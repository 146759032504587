import React, { Component } from "react";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import MetaTags from "./MetaTags";
import Trustbox from "./shared/Trustbox";
import "../styles/css/stately.css"

import { stateConfigs } from "../helpers/states_helper";

export default class WhatIsFig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: "el"
    };
  }

  handleSelect = el => () => this.setState({ product: el });

  render() {
    let metaTagData = {
      title:
        "Installment Loans for Fast Cash and Credit Building",
      og_title:
        "Installment Loans for Fast Cash and Credit Building",
      twitter_title:
        "Installment Loans for Fast Cash and Credit Building",
      description:
        "Fig's installment loan aims to meet your emergency cash needs while also helping to build up your credit score for a better financial future",
      og_description:
        "Fig's installment loan aims to meet your emergency cash needs while also helping to build up your credit score for a better financial future",
      twitter_description:
        "Fig's installment loan aims to meet your emergency cash needs while also helping to build up your credit score for a better financial future",
      og_image:
        "https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/product/el_color.png",
      twitter_image:
        "https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/product/el_color.png"
    };

    const activeStateNames = stateConfigs.filter(st => st.active).map(st => st.name);

    return (
      <div className="container">
        <MetaTags metaTagData={metaTagData} />

        <div className="grey">
          <div className="panel-row">
            <h4>{"What is a Fig Loan?"}</h4>
            <h5>A loan built for you, by people who get it.</h5>

            <p className="what-is-fig-mission-statement">
              {" "}
              Fig Loans help working class Americans <b>
                build credit{" "}
              </b>and <b>solve urgent cash needs </b>
            </p>

            <div className="general-product-features">
              <div className="gpf-half">
                <div className="gpf-row">
                  <div className="icon">
                    <img
                      src="https://figloans-static-images.s3.us-west-2.amazonaws.com/other/no_hidden_fees_2.png"
                      alt="transparent fees"
                    />
                  </div>
                  <div className="gpf-row-col">
                    <h3>
                      <b>Transparent Fees</b>
                    </h3>
                    <p>
                      The price you see is the price you pay. You will never be
                      charged more interest or a late fee from Fig.
                    </p>
                  </div>
                </div>

                <div className="clear" />
                <div className="gpf-row">
                  <div className="icon">
                    <img
                      src="https://figloans-static-images.s3.us-west-2.amazonaws.com/other/pay_at_your_own_pace_2.png"
                      alt="pay at your own pace"
                    />
                  </div>
                  <div className="gpf-row-col">
                    <h3>
                      <b>Pay At Your Pace</b>
                    </h3>
                    <p>
                      Need a couple of days? You can reschedule up to 28 days.
                      If you {"can't"} make your entire payment, {"we'll"} split
                      it in half for you. When life {"doesn't"} go as planned,
                      reschedule payments from your phone.
                    </p>
                  </div>
                </div>

                <div className="clear" />
              </div>
              <div className="gpf-half">
                <div className="gpf-row">
                  <div className="icon">
                    <img
                      src="https://figloans-static-images.s3.us-west-2.amazonaws.com/other/build_credit_2.png"
                      alt="build credit on the go"
                    />
                  </div>

                  <div className="gpf-row-col">
                    <h3>
                      <b>Build Credit Now</b>
                    </h3>
                    <p>
                      We report all payments to the 3 major credit bureaus
                      (Equifax, Experian, TransUnion) from day 1 because we want
                      to help build up your positive repayment history!
                    </p>
                  </div>
                </div>

                <div className="clear" />
                <div className="gpf-row">
                  <div className="icon">
                    <img
                      src="https://figloans-static-images.s3.us-west-2.amazonaws.com/other/next_day_funding.png"
                      alt="build credit on the go"
                    />
                  </div>

                  <div className="gpf-row-col">
                    <h3>
                      <b>Instant Funding*</b>
                    </h3>
                    <p>
                      Fig is the fastest! 5 minutes to apply for a loan, instant
                      cash deposit upon approval. Money today, when you need it.
                    </p>
                    <p>
                      *Loans funded to debit cards are deposited instantly. ACH
                      deposits may take several days.
                    </p>
                  </div>
                </div>

                <div className="clear" />
              </div>
            </div>

            <div className="clear" />
            <p className="what-is-fig-mission-statement">
              If you have been rejected by traditional lenders because of your
              credit score but have a steady income, see how a Fig Loan is
              different, and apply today - no hard pull to apply.**
            </p>

            <div className="what-is-fig-apply-button-container">
              <Link
                className="button"
                to="/app/email"
                onClick={() => window.scrollTo(0, 0)}
              >
                Apply in 5 Minutes!
              </Link>
            </div>

            <p>
              **Application approval is not guaranteed. Loan approval is
              dependent upon a number of factors.
            </p>
          </div>
        </div>
        <div className="panel-container white" id="cb">
          <div className="panel-row">
            <div>
              <div className="panel-centers">
                <h4 className="center">
                  Credit Builder Accounts: A proven way to build credit
                </h4>
              </div>

              <div className="panel-centers">
                <div className="content group" id="compare-cb-what-is-fig">
                  <h3 className="center green">
                    {"How's"} a Credit Builder different from a traditional
                    loan?*
                  </h3>
                  <p>*Credit Builders are only available to TX residents</p>
                  <div className="cols">
                    <div className="column half">
                      <div className="diagram">
                        <img
                          src="https://s3.us-west-2.amazonaws.com/figloans-static-images/credit_builder/credit_builder_flow.png"
                          alt="Transparent Terms"
                        />
                      </div>
                      <h3>Credit Builder</h3>
                      <p>
                        Upon approval and payment of the one-time account
                        opening fee, the lender sends your funds to a 3rd party
                        bank to hold for you. You make payment similar to a
                        traditional loan. Upon completion of your loan, the
                        lender releases the funds from the 3rd party to you.
                      </p>
                    </div>
                    <div className="column half">
                      <div className="diagram">
                        <img
                          src="https://s3.us-west-2.amazonaws.com/figloans-static-images/credit_builder/traditional_flow.png"
                          alt="Credit Reporting"
                        />
                      </div>
                      <h3>Traditional Loan</h3>
                      <p>
                        Upon approval, the lender sends your funds directly to
                        you. Afterwards you make payments until your loan is
                        paid off. Nothing occurs upon the completion of your
                        loan.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
            <div className="center">
              <br />
              <Link
                to="/?utm_source=google_adsl&utm_medium=cpc&utm_campaign=191220&utm_content=whatisfig"
                className="button"
                onClick={() => window.scrollTo(0, 0)}
              >
                But...I Really Need Money Today!!!
              </Link>
              <br />
              <h5>
                We get it! In that case, {"you'll"} need to apply for the
                traditional Fig Loan instead of the Credit Builder
              </h5>
              <Link
                to="/?utm_source=facebook_adsl&utm_medium=cpc&utm_campaign=191220&utm_content=whatisfig"
                className="button"
                onClick={() => window.scrollTo(0, 0)}
              >
                Learn More about the Fig Loan
              </Link>
            </div>
          </div>
        </div>
        <div className="panel-grey">
          <div className="mission">
            <div className="mission-text">
              <h4>{"Check Out Our Reviews!"}</h4>
              <Trustbox />
            </div>
          </div>
        </div>
        <div className="panel-container white">
          <div className="panel-row">
            <h4>Where We Serve</h4>
            <p>
              Our journey began in the Lone Star State. We love you Houston!
            </p>
            <p>
              As of{" "}
              {new Date().toLocaleString("default", {
                month: "long",
                year: "numeric",
              })}
              , {"we're"} able to serve{" "}
              {activeStateNames.slice(0, -1).join(", ")} and{" "}
              {activeStateNames.slice(-1)} residents.
            </p>
            <p>Fig is growing and we intend to branch out very soon.</p>
          </div>
          <div className="pie">
            <div className="panel-serve">
              <ul className="stately">
                {stateConfigs.map((state) => (
                  <li
                    data-state={state.abbreviation.toLowerCase()}
                    className={state.active && "active"}
                  >
                    {state.statelyCharacter}
                  </li>
                ))}
              </ul>
            </div>
            <div className="clear" />
          </div>
          <div className="clear" />
        </div>
        <div className="panel-grey">
          <div className="panel-row">
            <h4>More Info</h4>
            <div className="center">
              <Link
                to="/faq"
                className="button"
                onClick={() => window.scrollTo(0, 0)}
              >
                FAQ
              </Link>
              <br />
              <br />
              <Link
                to="/loan-information/compare-payday-loans"
                className="button"
                onClick={() => window.scrollTo(0, 0)}
              >
                How to Compare Lenders
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
