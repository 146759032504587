import React from "react";
import { Helmet } from "react-helmet";

const MissouriRates = ({ products }) => {
  products = products || {
    loans: {
      traditional_loan: {
        "300": { 4: { pmt: 106.86, apr: 1.9 } },
        "350": { 4: { pmt: 124.67, apr: 1.9 } },
        "400": { 4: { pmt: 142.47, apr: 1.9 } },
        "450": { 4: { pmt: 160.28, apr: 1.9 } },
        "500": { 4: { pmt: 178.09, apr: 1.9 } }
      }
    }
  };

  const productRateSpecification = Object.entries(products.loans.traditional_loan)
    .flatMap(([principal, terms]) => {
      return Object.entries(terms).map(([inst_num, { apr, pmt }]) => {
        return {
          principal: parseInt(principal),
          installment_num: inst_num,
          term_length: inst_num * 30,
          apr: apr,
          interest_charge: Math.round(inst_num * pmt * 100) / 100 - principal,
          total_repayment_amount: Math.round(inst_num * pmt * 100) / 100,
        };
      });
    });

  // Take the highest product; take every other product so they're spread across the book.
  const productsToShow = productRateSpecification.sort((a, b) => b.principal - a.principal) // Descending sort
    .filter((_el, idx) => idx % 2 == 0) // Take every other
    .slice(0, 3) // Take the top 3
    .sort((a, b) => a.principal - b.principal) // Ascending sort remaining elements

  const lastProductShown = productsToShow.slice(-1)[0];

  return (
    <div className="container">
      <Helmet>
        <title>Missouri Rates and Notices | Fig Loans</title>
        <meta
          name="description"
          content="Do you need a loan in Missouri? Fig Loans has the friendliest terms in the state!"
        />
        <meta
          property="og:description"
          content="Do you need a loan in Missouri? Fig Loans has the friendliest terms in the state!"
        />
      </Helmet>
      <div className="panel-grey">
        <div id="missouri">
          <div className="row">
            <div className="faq">
              <div className="mission-text center">
                <h4 className="modal-heading">Missouri</h4>
              </div>
              <p>
                Loan costs depend on the amount advanced and length of the loan.
                We do not currently charge an origination fee. Our maximum APR
                is currently 279%.
              </p>
              <p>
                <b>Fee Examples</b>
              </p>
              <p>
                <div className="disclosurewrap">
                  <div className="full-size">
                    <table>
                      <tbody>
                        <tr>
                          <th>Amount Advanced</th>
                          {productsToShow.map((o) => (
                            <td>${o.principal}.00</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Loan Length</th>
                          {productsToShow.map((o) => (
                            <td>{o.term_length} days</td>
                          ))}
                        </tr>
                        <tr>
                          <th>
                            Loan APR<sup>1</sup>
                          </th>
                          {productsToShow.map((o) => (
                            <td>{o.apr * 100}%</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Interest Charge</th>
                          {productsToShow.map((o) => (
                            <td>${o.interest_charge.toFixed(2)}</td>
                          ))}
                        </tr>
                        <tr>
                          <th>Total Repayment Amount</th>
                          {productsToShow.map((o) => (
                            <td>${o.total_repayment_amount.toFixed(2)}</td>
                          ))}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mobile-size">
                    <table>
                      {productsToShow.map((o) => (
                        <tr>
                          <th>
                            Sample ${o.principal} Dollar {o.installment_num}
                            -Month Loan
                          </th>
                          <td>
                            <b>Amount Advanced:</b> ${o.principal}.00
                          </td>
                          <td>
                            <b>Loan Length:</b> {o.term_length} days
                          </td>
                          <td>
                            <b>
                              Loan APR<sup>1</sup>:
                            </b>{" "}
                            {o.apr * 100}%
                          </td>
                          <td>
                            <b>Interest Charge:</b> $
                            {o.interest_charge.toFixed(2)}
                          </td>
                          <td>
                            <b>Total Repayment Amount:</b> $
                            {o.total_repayment_amount.toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>
                </div>
              </p>
              <p className="footnote">
                1. The APR is based on a{" "}
                {lastProductShown.installment_num} payment,{" "}
                {lastProductShown.term_length} day amortizing loan.
                The APR shown is based on the federal Truth in Lending Act and
                does not reflect specialized state APR calculations, if any.
                <br />
              </p>
              <p>
                The loan information shown here is an example and may not
                reflect the rates available on all loans. Before entering into
                any transaction, you should read the disclosure statements and
                contract that are provided to you by Fig Loans.
              </p>

              <p>
                {" "}
                <u> Optional Fees </u>{" "}
              </p>
              <p>
                {" "}
                You have the option of paying with a debit card. If you make a
                payment with a debit card, you will be charged a 3% processing
                fee that will be automatically added to your total payment.{" "}
              </p>
              <p>
                <b>Notices</b>
              </p>
              <p>
                This lender is regulated by Missouri Division of Finance. In the
                event of a complaint or concern, Missouri residents may contact
                the:
                <br />
                <br />
                Missouri Division of Finance
                <br />
                P.O. Box 716
                <br />
                Jefferson City, MO, 65102-0716
                <br />
                (573) 751-3463.
              </p>
              <p>
                <b>LICENSES</b>
              </p>
              <p>
                Fig Loans is a lender licensed by the State of Missouri Division
                of Finance.
              </p>
              <p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/licenses/2023/missouri_license.png"
                  alt="CAB License"
                  className="img-responsive faq-image"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissouriRates;
