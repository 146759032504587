export const standardProducts = {
  loans: {
    traditional_loan: {
      300: { 4: { apr: 1.9, interest: 1.9, pmt: 106.86 } }
    },
    community_loan: {
      300: { 4: { apr: 0.36, interest: 0.36, pmt: 80.71 } }
    },
    community_loan_extended: {
      300: { 4: { apr: 0.36, interest: 0.36, pmt: 80.71 } }
    },
    credit_builder: {
      500: { 12: { apr: 0.28, interest: 0.28, pmt: 48.25 } }
    }
  }
};