import React, { useState } from "react";
import { sendPageTracking } from "../../util/tracking_util";

const MoneyLionLink = ({ source, sessionId, text, userId, className, iframe }) => {
  const [ready, setReady] = useState(false);

  if (!iframe) {
    return (
      <a
        href={`https://hifiona.com/figloans/loans?tag.userid=${userId ||
          sessionId}&tag.source=${source}`}
        target="_blank"
        onClick={() =>
          sendPageTracking({
            behavior: `fiona-${source}`,
            anonymous_id: sessionId,
            user_id: userId
          })
        }
        className={className ? className : "moneylion-link"}
      >
        {text || "Click HERE to view your loan options!"}
      </a>
    );
  } else {
    if (!ready) {
      const script = document.createElement("script");
      script.src = "https://embed.hifiona.com/script/helper/bundle.js";
      script.onload = () => setReady(true);
      document.body.appendChild(script);
      return <div />;
    }
    return (
      <div>
        <iframe
          className="even-iframe"
          id="iFrameResizer0"
          scrolling="no"
          src={`https://embed.hifiona.com/ui/multi-product/index.html?partner=fig-loans-turnups-embed&access_token=03ff4d54-c2ee-4e67-9166-6de38a4520c9_10fb0cd3-49c0-4305-9b1c-9a36a10da178&company_uuid=b94e4fda-02c7-4d15-a2b0-f7a74e33b83c&productType=loan&pw_fiona=true&available_products=loan&tag.userid=${
            userId || sessionId
          }&tag.source=${source}`}
          style={{
            width: "100%",
            border: "none",
          }}
          title="MoneyLion Link"
        />
      </div>
    );
  }
};

export default MoneyLionLink;
