// @ts-check

export interface StateConfig {
  name: string,
  abbreviation: string,
  active: boolean,
  statelyCharacter: string
}

export const stateConfigs: StateConfig[] = [
  { name: "Alabama", abbreviation: "AL", active: false , statelyCharacter: "A" },
  { name: "Alaska", abbreviation: "AK", active: false , statelyCharacter: "B" },
  { name: "Arkansas", abbreviation: "AR", active: false , statelyCharacter: "C" },
  { name: "Arizona", abbreviation: "AZ", active: false , statelyCharacter: "D" },
  { name: "California", abbreviation: "CA", active: false , statelyCharacter: "E" },
  { name: "Colorado", abbreviation: "CO", active: false , statelyCharacter: "F" },
  { name: "Connecticut", abbreviation: "CT", active: false , statelyCharacter: "G" },
  { name: "Delaware", abbreviation: "DE", active: false , statelyCharacter: "H" },
  { name: "District of Columbia", abbreviation: "DC", active: false, statelyCharacter: "I" },
  { name: "Florida", abbreviation: "FL", active: true , statelyCharacter: "J" },
  { name: "Georgia", abbreviation: "GA", active: false , statelyCharacter: "K" },
  { name: "Hawaii", abbreviation: "HI", active: false , statelyCharacter: "L" },
  { name: "Idaho", abbreviation: "ID", active: false , statelyCharacter: "M" },
  { name: "Illinois", abbreviation: "IL", active: false , statelyCharacter: "N" },
  { name: "Indiana", abbreviation: "IN", active: false , statelyCharacter: "O" },
  { name: "Iowa", abbreviation: "IA", active: false , statelyCharacter: "P" },
  { name: "Kansas", abbreviation: "KS", active: false , statelyCharacter: "Q" },
  { name: "Kentucky", abbreviation: "KY", active: false , statelyCharacter: "R" },
  { name: "Louisiana", abbreviation: "LA", active: false , statelyCharacter: "S" },
  { name: "Massachusetts", abbreviation: "MA", active: false , statelyCharacter: "V" },
  { name: "Michigan", abbreviation: "MI", active: false , statelyCharacter: "W" },
  { name: "Minnesota", abbreviation: "MN", active: false , statelyCharacter: "X" },
  { name: "Maine", abbreviation: "ME", active: false , statelyCharacter: "T" },
  { name: "Maryland", abbreviation: "MD", active: false , statelyCharacter: "U" },
  { name: "Mississippi", abbreviation: "MS", active: false , statelyCharacter: "Y" },
  { name: "Missouri", abbreviation: "MO", active: true , statelyCharacter: "Z" },
  { name: "Montana", abbreviation: "MT", active: false , statelyCharacter: "a" },
  { name: "Nebraska", abbreviation: "NE", active: false , statelyCharacter: "b" },
  { name: "Nevada", abbreviation: "NV", active: false , statelyCharacter: "c" },
  { name: "New Hampshire", abbreviation: "NH", active: false , statelyCharacter: "d" },
  { name: "New Jersey", abbreviation: "NJ", active: false , statelyCharacter: "e" },
  { name: "New Mexico", abbreviation: "NM", active: false , statelyCharacter: "f" },
  { name: "New York", abbreviation: "NY", active: false , statelyCharacter: "g" },
  { name: "North Carolina", abbreviation: "NC", active: false , statelyCharacter: "h" },
  { name: "North Dakota", abbreviation: "ND", active: false , statelyCharacter: "i" },
  { name: "Ohio", abbreviation: "OH", active: true , statelyCharacter: "j" },
  { name: "Oklahoma", abbreviation: "OK", active: false , statelyCharacter: "k" },
  { name: "Oregon", abbreviation: "OR", active: false , statelyCharacter: "l" },
  { name: "Pennsylvania", abbreviation: "PA", active: false , statelyCharacter: "m" },
  { name: "Rhode Island", abbreviation: "RI", active: false , statelyCharacter: "n" },
  { name: "South Carolina", abbreviation: "SC", active: false , statelyCharacter: "o" },
  { name: "South Dakota", abbreviation: "SD", active: false , statelyCharacter: "p" },
  { name: "Tennessee", abbreviation: "TN", active: false , statelyCharacter: "q" },
  { name: "Texas", abbreviation: "TX", active: true , statelyCharacter: "r" },
  { name: "Utah", abbreviation: "UT", active: true , statelyCharacter: "s" },
  { name: "Vermont", abbreviation: "VT", active: false , statelyCharacter: "t" },
  { name: "Virginia", abbreviation: "VA", active: false , statelyCharacter: "u" },
  { name: "Washington", abbreviation: "WA", active: false , statelyCharacter: "v" },
  { name: "West Virginia", abbreviation: "WV", active: false , statelyCharacter: "w" },
  { name: "Wisconsin", abbreviation: "WI", active: true , statelyCharacter: "x" },
  { name: "Wyoming", abbreviation: "WY", active: false, statelyCharacter: "y"  }
];

export const stateConfigForAbbreviation = function (abbrev: string) : StateConfig | undefined {
  return stateConfigs.find(config => config.abbreviation == abbrev);
}