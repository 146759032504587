import React, { Component } from "react";
import "url-search-params-polyfill";
import { readCookie, createCookie } from "../helpers/cookie_helper";
import asyncComponent from "./AsyncComponent";
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  AuthRoute,
  ProtectedRoute,
  Monolith,
  htmlRoute,
  GatedRoute
} from "../util/route_util";
import { sendPageTracking } from "../util/tracking_util";
import { ipLookup } from "../util/user_api_util";
import monoliths from "../helpers/monolith_routes";
import { storeUtm } from "./shared/storageAvailable";

import Home from "./Home";
import "../styles/css/home.css";
import "../styles/css/navbar.css";
import "../styles/css/style.css";
import NavBar from "./NavBar_container";
import Footer from "./Footer";
// import FAQ from "./info/FAQ";
import Scholarship from "./info/Scholarship";
import LendingModel from "./abouts/LendingModel";
import TexasStory from "./abouts/TexasStory";
import CompareLoans from "./info/CompareLoans";
import CreditReporting from "./info/CreditReporting";
import LoanDictionary from "./info/LoanDictionary";
import Blog from "./blog/Blog";
import BlogPost from "./blog/BlogPost";
import LoanCalculator from "./info/LoanCalculator";
import WhatIsFig from "./WhatIsFig";
import SeoStatePage from "./SeoStatePage";
import Reviews from "./Reviews";
import Modal from "./Modal";
import MarketingEmailUnsubscribe from './help/MarketingEmailUnsubscribe';
import CollectionEmailUnsubscribe from "./help/CollectionEmailUnsubscribe.js";
import VeritecEmailUnsubscribe from "./help/VeritecEmailUnsubscribe.js";
import Banner from "./Banner.js";
import { UnserviceableReasons, getLoanUnserviceableReason } from "../helpers/unserviceable_loan_helper";
import { PageRoute } from "../helpers/nav_helper.js";

const AVPlaid = asyncComponent(() =>
  import("./partner/av/AVPlaid").then(
    import("../styles/css/loan_application.css")
  )
);
const { detect } = require("detect-browser");
const brow = detect();
const MobileDetect = require("mobile-detect");
const md = new MobileDetect(window.navigator.userAgent);

const Session = asyncComponent(() => import("./Session"));
const Application = asyncComponent(() =>
  import("./application/Application").then(
    import("../styles/css/loan_application.css")
  )
);

const CompareLoansMo = asyncComponent(() => import("./info/CompareLoansMo"));
const CompareLoansIl = asyncComponent(() => import("./info/CompareLoansIl"));
const NoticesRates = asyncComponent(() => import("./info/NoticesRates"));
const Esign = asyncComponent(() => import("./disclosures/Esign"));
const PrivacyGLBA = asyncComponent(() => import("./disclosures/PrivacyGLBA"));
const PrivacyPolicy = asyncComponent(() =>
  import("./disclosures/PrivacyPolicy")
);
const EmailInfomational = asyncComponent(() =>
  import("./disclosures/EmailInfomational")
);

const CcpaOptOut = asyncComponent(() => import("./disclosures/CcpaOptOut.js"));
const CcpaRequestForm = asyncComponent(() => import("./CcpaRequestForm.js"));
const TCPA = asyncComponent(() => import("./disclosures/TCPA"));
const Testimonial = asyncComponent(() => import("./disclosures/Testimonial"));
const CSO = asyncComponent(() => import("./disclosures/CSO"));
const CAB = asyncComponent(() => import("./disclosures/CAB"));
const Cancellation = asyncComponent(() => import("./disclosures/Cancellation"));
const License = asyncComponent(() => import("./disclosures/License"));
const Profile = asyncComponent(() => import("./profile/Profile"));
const CommunityLoans = asyncComponent(() =>
  import("./CommunityLoans").then(import("../styles/css/community_loans.css"))
);
const CommunityLoansPartner = asyncComponent(() =>
  import("./CommunityLoansPartner").then(
    import("../styles/css/community_loans.css")
  )
);

const TermsOfService = asyncComponent(() =>
  import("./disclosures/TermsOfService")
);
const BetterLoansMo = asyncComponent(() => import("./info/BetterLoansMo"));
const BetterLoansTx = asyncComponent(() => import("./info/BetterLoansTx"));
const BetterLoansIl = asyncComponent(() => import("./info/BetterLoansIl"));
const Survey = asyncComponent(() =>
  import("./Survey").then(import("../styles/css/loan_application.css"))
);
const CreditBuilder = asyncComponent(() =>
  import("./CreditBuilder").then(import("../styles/css/credit_builder.css"))
);

const StatementUpload = asyncComponent(() =>
  import("./StatementUpload").then(import("../styles/css/loan_application.css"))
);

const IdentityUpload = asyncComponent(() =>
  import("./IdentityUpload").then(import("../styles/css/loan_application.css"))
);

const CovidUpload = asyncComponent(() =>
  import("./CovidUpload").then(import("../styles/css/loan_application.css"))
);

const PaystubUpload = asyncComponent(() =>
  import("./PaystubUpload").then(import("../styles/css/loan_application.css"))
);

const UploadConfirmation = asyncComponent(() => import("./UploadConfirmation"));
const VideoConfirmation = asyncComponent(() => import("./VideoConfirmation"));
const PrequalConfirmation = asyncComponent(() =>
  import("./confirmations/PrequalConfirmation")
);

const ResetPassword = asyncComponent(() =>
  import("./profile/ResetPassword").then(
    import("../styles/css/loan_application.css")
  )
);

const UnlockAccount = asyncComponent(() =>
  import("./profile/UnlockAccount").then(
    import("../styles/css/loan_application.css")
  )
);

const PasswordConfirmation = asyncComponent(() =>
  import("./profile/PasswordConfirmation")
);

const UnlockConfirmation = asyncComponent(() =>
  import("./profile/UnlockConfirmation")
);

const NewPassword = asyncComponent(() =>
  import("./profile/NewPassword").then(
    import("../styles/css/loan_application.css")
  )
);

const CaAssignedToTrueAccord = asyncComponent(() =>
  import("./CaAssignedToTrueAccord.js")
);

const ScreenshotTool = asyncComponent(() =>
  import("./ScreenshotTool").then(import("../styles/css/user_profile.css"))
);

// const OneOff = asyncComponent(() =>
//   import("./OneOff").then(import("../styles/css/loan_application.css"))
// );

const ThirdParty = asyncComponent(() =>
  import("./ThirdParty").then(import("../styles/css/loan_application.css"))
);

// const OneOffPayment = asyncComponent(() =>
//   import("./OneOffPayment").then(import("../styles/css/user_profile.css"))
// );
const GlobalPayments = asyncComponent(() =>
  import("./shared/GlobalPayments/GlobalPayments").then(
    import("../styles/css/user_profile.css")
  )
);

const Press = asyncComponent(() => import("./press/Press"));

const AppFinish = asyncComponent(() =>
  import("./application/app_finish/AppFinish").then(
    import("../styles/css/loan_application.css"),
    import("../styles/css/user_profile.css")
  )
);

const PrequalContainer = asyncComponent(() =>
  import("./application/app_finish/PrequalAcceptance/PrequalContainer").then(
    import("../styles/css/loan_application.css")
  )
);

const AcceptMod = asyncComponent(() => import("./application/AcceptMod"));
const Help = asyncComponent(() => import("./help/Help"));
const PaymentMethods = asyncComponent(() => import("./profile/PaymentMethods"));
const BankAccount = asyncComponent(() => import("./profile/BankAccount"));
const DebitCard = asyncComponent(() => import("./profile/DebitCard"));
const PaymentSelection = asyncComponent(() => import("./profile/PaymentSelection"));
const PaymentsHelp = asyncComponent(() => import("./help/PaymentsHelp"));
const NewLoanHelp = asyncComponent(() => import("./help/NewLoanHelp"));
const UpdateInfo = asyncComponent(() => import("./help/UpdateInfo"));
const LoginHelp = asyncComponent(() => import("./help/LoginHelp"));
const RandomHelp = asyncComponent(() => import("./help/RandomHelp"));

const Jobs = asyncComponent(() => import("./info/Jobs"));
const ApplyJobs = asyncComponent(() =>
  import("./info/ApplyJobs").then(import("../styles/css/loan_application.css"))
);
const ApplyCSS = asyncComponent(() =>
  import("./info/ApplyCSS").then(import("../styles/css/user_profile.css"))
);

const ReapplyReminder = asyncComponent(() =>
  import("./confirmations/ReapplyReminder").then(
    import("../styles/css/loan_application.css")
  )
);

const Representation = asyncComponent(() =>
  import("./confirmations/Representation").then(
    import("../styles/css/loan_application.css")
  )
);

const VideoUpload = asyncComponent(() =>
  import("./VideoUpload").then(import("../styles/css/loan_application.css"))
);

const ReviewUpload = asyncComponent(() =>
  import("./ReviewUpload").then(import("../styles/css/loan_application.css"))
);

const Page5 = asyncComponent(() =>
  import("./application/Page5_container").then(
    import("../styles/css/loan_application.css")
  )
);

const IncomeVerification = asyncComponent(() =>
    import("./application/IncomeVerification").then(
        import("../styles/css/loan_application.css")
    )
);

const InstantRepeat = asyncComponent(() =>
  import("./application/InstantRepeat").then(
    import("../styles/css/loan_application.css")
  )
);
const ErrorBoundary = asyncComponent(() =>
  import("./ErrorBoundary").then(import("../styles/css/custom.css"))
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { loaded: true, routeChanged: false };
  }

  componentDidMount() {
    const splitPath = this.props.location.pathname.split("/");

    if (splitPath.length === 4 && splitPath[1] === "app_finish") {
      const forced = splitPath[3];
      const path = `${this.props.location.pathname}${this.props.location.search}`;
      this.props.changeLoading(true);
      this.props.forceLogin(forced, () => {
        if (
          `${this.props.location.pathname}${this.props.location.search}` !==
          path
        ) {
          this.props.history.push(path);
        }
        this.props.changeLoading(false);
      });
    }

    if (!this.props.currentUser && this.props.location.pathname.includes("goalry")) {
      const browser = {
        platform: { name: md.os(), id: brow.os, version: brow.version },
        device: {
          name: md.mobile(),
          id: md.version(),
          phone: Boolean(md.mobile()),
          tablet: Boolean(md.tablet())
        },
        base: {
          name: brow.name,
          version: brow.version
        }
      };

      const behavior = {
        utm_source: "goalry",
        behavior: `${window.location.href} ${JSON.stringify(browser)}`,
        anonymous_id: readCookie("fig-session")
      };
      sendPageTracking(behavior);
    }

    if (!this.props.currentUser && this.props.location.pathname.includes("evenfinancial")) {
      const browser = {
        platform: { name: md.os(), id: brow.os, version: brow.version },
        device: {
          name: md.mobile(),
          id: md.version(),
          phone: Boolean(md.mobile()),
          tablet: Boolean(md.tablet())
        },
        base: {
          name: brow.name,
          version: brow.version
        }
      };
      const behavior = {
        utm_source: "evenfinancial",
        behavior: `${window.location.href} ${JSON.stringify(browser)}`,
        anonymous_id: readCookie("fig-session")
      };
      sendPageTracking(behavior);
    }

    const rawPath = this.props.location.search === "" ? this.props.location.pathname : this.props.location.search;

    if (this.props.location.search) {
      const search = this.props.location.search;
      const params = new URLSearchParams(search);

      if (!this.props.currentUser && params.get("forced")) {
        const path = `${this.props.location.pathname}${this.props.location.search}`;
        this.props.changeLoading(true);
        this.props.forceLogin(params.get("forced"), () => {
          if (`${this.props.location.pathname}${this.props.location.search}` !== path) {
            this.props.history.push(path);
          }
          this.props.changeLoading(false);
        });
      }

      const utm = {
        source: params.get("utm_source"),
        medium: params.get("utm_medium"),
        term: params.get("utm_term"),
        content: params.get("utm_content"),
        campaign: params.get("utm_campaign"),
        rawPath
      };

      if (utm.source === "pennyhoarder") {
        const behavior = {
          utm_source: utm.source,
          utm_medium: utm.medium,
          utm_content: utm.content,
          utm_campaign: utm.campaign,
          utm_term: utm.term,
          behavior: "pennyhoarder",
          anonymous_id: readCookie("fig-session"),
          details: JSON.stringify({ penny_id: params.get("penny_id") })
        };
        sendPageTracking(behavior);
      }

      if (params.get("sscid")) {
        utm["source"] = "sscid";
        utm["content"] = params.get("sscid");
      }

      for (var key in utm) {
        if (utm[key] !== null) {
          createCookie(`utm_${key}`, utm[key]);
        }
      }
      if (rawPath.includes("utm")) {
        this.props.setUtm(utm);
        storeUtm(utm);
      }
    } else {
      if (rawPath.includes("utm")) {
        const utm = {
          rawPath
        };
        this.props.setUtm(utm);
        storeUtm(utm);
      }
    }

    if (this.props.currentUser) {
      this.props.fetchUserInfo(this.props.currentUser.email);
    }

    const amount = parseInt(readCookie("loan_amount"), 10);
    const state = readCookie("loan_state");
    const length = parseInt(readCookie("loan_length"), 10);

    if (amount && length) this.props.setApplication({ amount, length });
    if (state) this.props.setLocation(state);

    if (this.props.location.pathname.length > 0) {
      require("../styles/bundled");
      this.setState({ routeChanged: true });
    }

    this.props.history.listen(() => {
      if (!this.state.routeChanged) {
        require("../styles/bundled");
        this.setState({ routeChanged: true });
      }
      this.props.clearErrors();
    });

    this.cookiePreferencesPopUpButton = null;
    this.setCookiePreferencesPopUpButtonClickRef = element => {      
      this.cookiePreferencesPopUpButton = element;
    };

    this.handleCookiePreferencesPopUpButtonClick = () => {
      this.cookiePreferencesPopUpButton.click();
    };
  }

  render() {
    if (
      this.props.currentUser &&
      getLoanUnserviceableReason(this.props.currentUser) ===
        UnserviceableReasons.CA_SHUTDOWN &&
      !window.location.href.includes(PageRoute.CA_ASSIGNED_TO_TA)
    ) {
      return <Redirect to={PageRoute.CA_ASSIGNED_TO_TA} />;
    } 
    return (
      <ErrorBoundary>
        <div className="App">
          {this.props.location.pathname.includes("app_finish") &&
          this.props.loading ? (
            <Modal loading={this.props.loading}>
              <div id="welcome-to-fig">
                <h2 className="animated">
                  {"We're"} processing your information.
                  <br /> This will just take a few seconds.
                </h2>
                <h2 className="animated">
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </h2>
              </div>
            </Modal>
          ) : null}
          <header className="home" role="banner">
            <NavBar />
          </header>
          {this.props.banner && this.props.banner.message &&
            <div className="banner-message-container">
              <br></br>
              <Banner />
            </div>
          }
          <TransitionGroup className="transition-group">
            <CSSTransition
              key={this.props.location.key}
              timeout={{ enter: 300, exit: 300 }}
              classNames={"fade"}
            >
              <section className="route-section">
                <Switch location={this.props.location}>
                  {htmlRoute({
                    path: "/app",
                    component: Application
                  })}
                  <Route
                    path="/first10"
                    component={() => <Redirect to="/app/email?promo=first10" />}
                  />
                  <Route
                    path="/loans/application_servers/app_start"
                    component={() => <Application edit={true} />}
                  />
                  <Route
                    exact
                    path="/community-loans/access-ventures/bank-verification"
                    component={() => <AVPlaid />}
                  />

                  <AuthRoute path="/signin" component={Session} />
                  <Route
                    exact
                    path="/thanks-15"
                    component={() => <Redirect to="signin/thanks-15" />}
                  />
                  {htmlRoute({
                    exact: true,
                    path: "/about/fig-texas-story",
                    component: TexasStory
                  })}
                  {htmlRoute({
                    exact: true,
                    path: "/about/responsible-lending-model",
                    component: LendingModel
                  })}
                  {htmlRoute({
                    exact: true,
                    path: "/loan-information/compare-payday-loans",
                    component: CompareLoans
                  })}
                  <Route
                    exact
                    path="/what-is-fig"
                    component={() => {
                      let state = readCookie("state");
                      if (!state) {
                        ipLookup().then(response => {
                          if (
                            response.body.country_name &&
                            response.body.country_name === "United States"
                          ) {
                            state = response.body.region_code;
                            createCookie("state", state);
                          }
                        });
                      }
                      return <WhatIsFig state={state} />;
                    }}
                  />
                  <Route exact path="/reviews" component={Reviews} />
                  {htmlRoute({
                    exact: true,
                    path: "/loan-information/credit-reporting",
                    component: CreditReporting
                  })}
                  {htmlRoute({
                    exact: true,
                    path: "/loan-information/loan-terms-dictionary",
                    component: LoanDictionary
                  })}
                  <Route
                    path="/faq"
                    component={() => {
                      window.location.href = "https://support.figloans.com";
                      return null;
                    }}
                  />
                  <Route path="/esign" component={Esign} />
                  <Route path="/privacy-glba" component={PrivacyGLBA} />
                  <Route 
                    path="/privacy"
                    component={() => <PrivacyPolicy handleCookiePreferencesPopUpButtonClick={this.handleCookiePreferencesPopUpButtonClick} />} 
                  />
                  <Route path="/tcpa" component={TCPA} />
                  <Route
                    path="/ccpa-opt-out"
                    component={() => <CcpaOptOut handleCookiePreferencesPopUpButtonClick={this.handleCookiePreferencesPopUpButtonClick} />}
                  />
                  <Route
                    path="/ccpa-request-form"
                    component={() => <CcpaRequestForm handleCookiePreferencesPopUpButtonClick={this.handleCookiePreferencesPopUpButtonClick} />}
                  />
                  <Route
                    path="/testimonial_disclaimer"
                    component={Testimonial}
                  />
                  <Route
                    path="/why-am-i-not-receiving-emails"
                    component={EmailInfomational}
                  />
                  <Route path="/cso" component={CSO} />
                  <Route path="/cab" component={CAB} />
                  <GatedRoute
                    path="/cancellation_notice"
                    component={Cancellation}
                  />
                  <Route
                    path="/license_cab"
                    component={() => <License type="cab" />}
                  />
                  <Route
                    path="/license_342"
                    component={() => <License type="342" />}
                  />
                  <Route
                    path="/license_mo"
                    component={() => <License type="mo" />}
                  />
                  <Route
                    path="/license_oh"
                    component={() => <License type="oh" />}
                  />
                  <Route
                    path="/license_ut"
                    component={() => <License type="ut" />}
                  />
                  <Route path="/terms_of_service" component={TermsOfService} />
                  <ProtectedRoute exact path="/me" component={Profile} />
                  <ProtectedRoute path="/me/:action" component={Profile} />

                  <Route
                    path="/installment-loan/:state"
                    component={props => {
                      if (
                        [
                          "texas",
                          "illinois",
                          "missouri",
                          "ohio",
                          "florida",
                          "utah",
                          "new-mexico"
                        ].includes(props.match.params.state.toLowerCase())
                      ) {
                        return <SeoStatePage {...props} />;
                      } else {
                        return <Redirect to="/" />;
                      }
                    }}
                  />
                  <Route
                    path="/installment-loan/"
                    component={props => {
                      return <SeoStatePage {...props} />;
                    }}
                  />
                  <Route
                    exact
                    path="/community-loans/:partner"
                    component={CommunityLoansPartner}
                  />

                  <Route path="/community-loans" component={CommunityLoans} />
                  <Route
                    path="/serve"
                    component={() => <Redirect to="/community-loans/serv" />}
                  />

                  {htmlRoute({
                    path: "/scholarship",
                    component: Scholarship
                  })}
                  {htmlRoute({
                    exact: true,
                    path: "/loan-information/better-loans/missouri",
                    component: BetterLoansMo
                  })}
                  {htmlRoute({
                    path: "/loan-information/better-loans",
                    component: BetterLoansTx
                  })}
                  {htmlRoute({
                    exact: true,
                    path: "/loan-information/compare-payday-loans/missouri",
                    component: CompareLoansMo
                  })}

                  {htmlRoute({
                    exact: true,
                    path: "/credit-builder",
                    component: CreditBuilder
                  })}

                  <Route
                    exact
                    path="/credit-builder/:partner"
                    component={Application}
                  />
                  {htmlRoute({
                    exact: true,
                    path: "/blog/posts/:path",
                    component: BlogPost
                  })}
                  {htmlRoute({
                    exact: true,
                    path: "/blog/:tag?",
                    component: Blog
                  })}
                  {htmlRoute({
                    exact: true,
                    path: "/loanCalculator",
                    component: LoanCalculator
                  })}

                  <Route
                    path="/glba_tx"
                    component={() => <PrivacyGLBA site="TX" />}
                  />

                  {htmlRoute({
                    exact: true,
                    path: "/press",
                    component: Press
                  })}
                  {htmlRoute({
                    exact: true,
                    path: "/press/:path",
                    component: Press
                  })}

                  <Route
                    exact
                    path="/loans/statements/new/:url"
                    component={StatementUpload}
                  />

                  <Route
                    path="/loans/payments/:loan_url/en"
                    render={props => {
                      return (
                        <GlobalPayments
                          {...props}
                          show={true}
                          purpose={"both"}
                          oneOff={true}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/loans/repayment"
                    render={props => {
                      return (
                        <GlobalPayments
                          {...props}
                          show={true}
                          purpose={"both"}
                          repay={true}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/repayment"
                    render={props => {
                      return (
                        <GlobalPayments
                          {...props}
                          show={true}
                          purpose={"both"}
                          repay={true}
                        />
                      );
                    }}
                  />
                  <Route
                    exact
                    path="/loans/custom"
                    render={props => {
                      return (
                        <GlobalPayments
                          {...props}
                          show={true}
                          purpose={"add"}
                          custom={true}
                        />
                      );
                    }}
                  />

                  <Route
                    exact
                    path="/loans/third_party"
                    component={ThirdParty}
                  />

                  <Route
                    exact
                    path="/users/identity/new/:loan_url"
                    component={IdentityUpload}
                  />

                  <Route
                    exact
                    path="/users/covid/new/:loan_url"
                    component={CovidUpload}
                  />

                  <Route
                    exact
                    path="/loans/paystubs/new/:url"
                    component={PaystubUpload}
                  />

                  <GatedRoute
                    exact
                    path="/video/new/:campaign"
                    component={VideoUpload}
                  />

                  <GatedRoute
                    exact
                    path="/review/new"
                    component={ReviewUpload}
                  />

                  <GatedRoute exact path="/video/new" component={VideoUpload} />
                  <Route
                    exact
                    path="/video/confirmation"
                    component={VideoConfirmation}
                  />

                  <Route
                    exact
                    path="/loans/statements/confirmation"
                    component={() => <UploadConfirmation type={"statement"} />}
                  />

                  <Route
                    exact
                    path="/loans/paystubs/confirmation"
                    component={() => <UploadConfirmation type={"paystub"} />}
                  />

                  <Route
                    exact
                    path="/loans/identity/confirmation"
                    component={() => <UploadConfirmation type={"identity"} />}
                  />

                  <Route
                    exact
                    path="/loans/covid/confirmation"
                    component={() => <UploadConfirmation type={"covid"} />}
                  />

                  <Route
                    exact
                    path="/reminder_email"
                    component={ReapplyReminder}
                  />
                  <Route
                    exact
                    path="/representation"
                    component={Representation}
                  />
                  <Route
                    exact
                    path="/prequal/confirmation"
                    component={PrequalConfirmation}
                  />
                  <Route
                    exact
                    path="/users/password/new"
                    component={ResetPassword}
                  />
                  <Route
                    exact
                    path="/users/password/confirmation"
                    component={PasswordConfirmation}
                  />
                  <Route
                    path="/api/users/password/edit"
                    component={NewPassword}
                  />
                  <Route
                    exact
                    path="/users/unlock/new"
                    component={UnlockAccount}
                  />
                  <Route
                    exact
                    path="/users/unlock/confirmation"
                    component={UnlockConfirmation}
                  />
                  <Route exact path="/decline_counter" component={AcceptMod} />
                  <GatedRoute exact path={PageRoute.CA_ASSIGNED_TO_TA} component={CaAssignedToTrueAccord} />
                  <GatedRoute
                    exact
                    path="/app_finish/:partner/:forced"
                    component={AppFinish}
                  />
                  <GatedRoute path="/app_finish" component={AppFinish} />
                  <ProtectedRoute
                    path="/prequal"
                    component={PrequalContainer}
                  />
                  <GatedRoute
                    exact
                    path="/plaid_redirect"
                    component={Page5}
                    plaidRedirect={true}
                  />
                  <GatedRoute
                      exact
                      path="/income_verification"
                      component={IncomeVerification}
                  />
                  <GatedRoute exact path="/accounts" component={PaymentMethods} />
                  <GatedRoute exact path="/accounts/new-bank" component={BankAccount} />
                  <GatedRoute exact path="/accounts/new-debit" component={DebitCard} />
                  <GatedRoute exact path="/payment" component={PaymentSelection} />
                  <GatedRoute
                    exact
                    path="/help/payments"
                    component={PaymentsHelp}
                  />
                  <GatedRoute
                    exact
                    path="/help/refinance"
                    component={NewLoanHelp}
                  />
                  <GatedRoute
                    exact
                    path="/help/update_info"
                    component={UpdateInfo}
                  />
                  <GatedRoute exact path="/help/login" component={LoginHelp} />
                  <GatedRoute
                    exact
                    path="/help/random"
                    component={RandomHelp}
                  />
                  <GatedRoute path="/help" component={Help} />
                  <GatedRoute path="/instant" component={InstantRepeat} />

                  <Route
                    exact
                    path="/jobs/customer_service_supplemental"
                    component={ApplyCSS}
                  />

                  <Route
                    exact
                    path="/surveys/:path/:email"
                    component={Survey}
                  />

                  <Route
                    exact
                    path="/emails/unsubscribe"
                    component={MarketingEmailUnsubscribe}
                    />

                  <Route
                    exact
                    path="/emails/unsubscribe_collection"
                    component={CollectionEmailUnsubscribe}
                    />

                  <Route
                    exact
                    path="/emails/unsubscribe_veritec"
                    component={VeritecEmailUnsubscribe}
                    />

                  {// monolith sites
                  monoliths.map((path, i) => (
                    <Monolith key={i} path={path} />
                  ))}

                  <Route path="/" component={Home} />
                </Switch>
                <footer role="contentinfo">
                  <Footer handleCookiePreferencesPopUpButtonClick={this.handleCookiePreferencesPopUpButtonClick} />
                </footer>
              </section>
            </CSSTransition>
          </TransitionGroup>
          <div className="oneTrustPopUpButton">
            {/* <!-- OneTrust Cookies Settings button start --> */}
            <button ref={this.setCookiePreferencesPopUpButtonClickRef} id="ot-sdk-btn" className="ot-sdk-show-settings column disclosures desktop">Cookie Settings</button>
            {/* <!-- OneTrust Cookies Settings button end --> */}
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

export default withRouter(App);

