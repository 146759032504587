import React, { useState, useEffect } from "react";
import { setState } from "../actions/info_actions";
import { changeLoading } from "../actions/loader_actions";
import { sendPageTracking } from "../util/tracking_util";
import { ipLookup } from "../util/user_api_util";
import { createCookie, readCookie } from "../helpers/cookie_helper";
import Modal from "./Modal";
import { timeout } from "../helpers/request_helper";
import { useScrollPosition } from "../helpers/useScrollPosition";

import { connect } from "react-redux";

import { stateConfigs } from "../helpers/states_helper";

const StateSelector = ({ setLocation, load, state, utm, sessionId }) => {
  const [ipState, setIpState] = useState();
  const [warningModalComp, setWarningModalComp] = useState(null);
  const [ipLookupRequestMade, setIpLookupRequestMade] = useState(false);

  useEffect(() => {
    if (state) {
      setLocation(state);
      createCookie("state", state);
    }
  }, [state]);

  useScrollPosition(
    ({ currPos }) => {
      if (
        currPos.y >
        (document.body.scrollHeight - window.innerHeight) * 0.01
      ) {
        if (!state && !ipLookupRequestMade) {
          timeout(3000, ipLookup())
            .then((response) => {
              if (
                response.body.country_name &&
                response.body.country_name !== "United States" &&
                readCookie("outsideUsWarning") === null
              ) {
                triggerOutsideUsWarning();
              } else if (
                response.body.country_name &&
                response.body.country_name === "United States"
              ) {
                setIpState(response.body.region_code);
              }
            })
            .catch((error) => {});
          setIpLookupRequestMade(true);
        }
      }
    },
    [ipState, state],
    null,
    true,
    250
  );

  useEffect(() => {
    if (ipState && !state) {
      if (!["FL", "IL", "MO", "OH", "TX", "UT"].includes(ipState)) {
        const behavior = {
          utm_source: utm.source,
          utm_medium: utm.medium,
          utm_content: utm.content,
          utm_campaign: utm.campaign,
          utm_term: utm.term,
          behavior: "state-selector-other",
          anonymous_id: sessionId,
        };
        sendPageTracking(behavior);
      }
      load();
      setLocation(ipState);
      createCookie("state", ipState);
    }
  }, [ipState]);

  const triggerOutsideUsWarning = () => {
    const comp = (
      <div>
        <p>
          Fig Loans does not currently serve customers outside the United
          States. Any organization claiming to be associated with Fig Loans to
          do business outside of the U.S. is not associated with us. We hope to
          be able to bring our financial products to you soon!
        </p>
      </div>
    );
    setWarningModalComp(comp);
  };

  const activeStates = stateConfigs.filter(x => x.active)
  const inactiveStates = stateConfigs.filter(x => !x.active)

  return (
    <div id="state_selector">
      <select
        id="state_selection"
        onChange={(e) => {
          if (!["FL", "MO", "OH", "TX", "UT"].includes(e.target.value)) {
            const behavior = {
              utm_source: utm.source,
              utm_medium: utm.medium,
              utm_content: utm.content,
              utm_campaign: utm.campaign,
              utm_term: utm.term,
              behavior: "state-selector-other",
              anonymous_id: sessionId,
            };
            sendPageTracking(behavior);
          }
          load();
          setLocation(e.target.value);
        }}
        value={state ? state : ipState ? ipState : ""}
      >
        <option value="" disabled hidden>
          Select your state here!
        </option>
        {activeStates.map(state => (
          <option value={state.abbreviation}>{state.name}</option>
        ))}
        <option value="" disabled>
          -----------------
        </option>
        {inactiveStates.map(state => (
          <option value={state.abbreviation}>{state.name}</option>
        ))}
      </select>
      {warningModalComp ? (
        <Modal
          closeAction={() => {
            createCookie("outsideUsWarning", "true", null);
          }}
        >
          {warningModalComp}
        </Modal>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLocation: (state) => dispatch(setState(state)),
  load: () => dispatch(changeLoading(true)),
});

const mapStateToProps = (state, props) => ({
  state: state.info.location || props.state,
  utm: state.entities.utm,
  sessionId: state.session.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(StateSelector);
